function ui(state, action) {
	if (typeof state === 'undefined') {
		return 0;
	}
	switch (action.type) {
		//SET_STATE is meant as a utility function to force a specific screen state for screenshots
		case 'SET_STATE':
			return Object.assign({}, state, action);
		case 'SET_CURRENT_PAGE':
			return {
				...state,
				currentPage: {
					...state.currentPage,
					...action.currentPage
				}
			};
		case "FLIP_CARD":
            return {
                ...state,
                cards: {
                ...state.cards,
                ...action.cards,
                status: action.value,
                index: action.index
            }
        };
        case "SET_EMAIL_OVERLAY_STATE":
            return {
                ...state,
                emailOverlay: action.emailOverlay,
        	};
        case "UPDATE_FORM_VALIDATION":
            return {
                ...state,
                emailFormValidation: {
                invalidEmail: action.invalidEmail,
                emailNotEqual: action.emailNotEqual,
            }
        };
        case "SET_THANK_YOU_POPUP_STATE":
            return {
                ...state,
                thankYouPopup: {
                thankYouPopupOpen: action.thankYouPopupOpen,
                thankYouPopupEmail: action.thankYouPopupEmail,
            }
        };
		case "UPDATE_COINS_ITEMS":
            return {
                ...state,
                coins: {
                ...state.coins,
                items: action.coins.items
            }
        };
		case 'SET_OVERLAY_STATE':
			return {
				...state,
				overlay: action.overlay
			};
		case 'SET_MODAL_STATE':
			return {
				...state,
				modal: action.modal,
				overlay: (!action.modal) ? false : true
			};
		case 'SET_BUBBLE_SLIDE_INDEX':
			return {
				...state,
				bubble: {
					...state.bubble,
					...action.bubble
				}
			};
		case "SET_HOVER_TEXT":
            return {
                ...state,
                show: {
                ...state.show,
                ...action.show,
                selected: action.selected ? action.selected : null,
				text: action.text,
				index: action.index
				}
			};
		case 'SET_CURRENT_BREAKPOINT': 
			return {
				...state,
				currentBreakpoint: action.currentBreakpoint
			};
		case 'TOGGLE_MENU':
			return {
				...state,
				menu: {
					...state.menu,
					...action.menu
				},
				overlay: (action.menu.open || state.menu.open && action.menu.open !== false) ? {zIndex:-1} : false
			}
		case 'SET_GREETING_CARD':
			return {
				...state,
				greetingCard: action.card || null
			}
		case "TOGGLE_HELP_DROPDOWN":
			return {
			  ...state,
			  helpDropDown: {
				isOpen: action.helpDropDown.isOpen ?  true : false
			  }
			}
		case 'SET_BAG_INCLUDES': 
			return {
				...state,
				bag: action.bag
			}
		case 'SET_KALEIDOSCOPE':
			return {
				...state,
				kaleidoscope: {
					...action.kaleidoscope,
					firework: action.firework ? action.firework : null
				}
			};
		case "UPDATE_EAT_WELL_STATE":
            return {
            	...state,
            	eatWell: {
            		selectedAnswer: action.eatWell.selectedAnswer,
            		selectedId: action.eatWell.selectedId
            	}
            }
		case 'UPDATE_FINDING_BALANCE_ERROR':
			return {
				...state,
				findingBalance: {
					...action.findingBalance,
					showError: action.findingBalance.showError
				}
			}
		case "TOGGLE_IMAGES":
			return {
				...state,
				thumbnailComponent: {
					showImage: action.thumbnailComponent.showImage
				}
			};
		case 'UPDATE_COLLAPSIBLE_SECTIONS':
			return {
				...state,
				collapsibleSections: {
					...state.collapsibleSections,
					['collapsibleSection' + action.collapsibleSectionID]: action.collapsibleSectionState
				}
			};
		case "UPDATE_CYCLE_STATE":
			return {
				...state,
				cycleWidget: action.index
			}
		case "SET_LIFE_BALANCE_RESULTS":
			return {
				...state,
				lifeBalanceResults: action.lifeBalanceResults
			}
		case "UPDATE_POPUP_STATE":
			return {
				...state,
				popup: action.popup
			}
		default:
			return state;
	};
}

export default ui;
