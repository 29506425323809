export default class BreakpointListener {
	constructor() {
		this.breakpoints = {
			small: {
				min: 0,
				max: 767
			},
			medium: {
				min: 768,
				max: 959
			},
			large: {
				min: 960,
				max: 1280
			},
			xlarge: {
				min: 1281,
				max: 1920
			},
			xxlarge: {
				min: 1921,
				max: Number.POSITIVE_INFINITY
			}
		};
		this.init();
	}

	init() {
		if (matchMedia) {
			const bp = this.breakpoints;
			Object.keys(bp).forEach(
				(key) => {
					let value = bp[key];
					bp[key].mediaQuery = window.matchMedia('(min-width: '+value.min+'px) and (max-width: '+value.max+'px)');
	
					if (bp[key].mediaQuery.matches) {
						store.dispatch({ type: 'SET_CURRENT_BREAKPOINT', currentBreakpoint: key });
					}
	
					bp[key].mediaQuery.addListener((res) => {
						if (res.matches) {
							store.dispatch({ type: 'SET_CURRENT_BREAKPOINT', currentBreakpoint: key });
						}
					});
				}	
			);
		}
	}
}