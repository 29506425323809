class External {
    constructor(link) {
        this.elements = {
            link,
            external: document.querySelector('.third-party-popup__btn a')
        };

        this.handleClick();
    }

    handleClick() {
        this.elements.link.onclick = e => {
            e.preventDefault();
            this.updateLink();
            this.openModal();
        };
    }

    updateLink() {
        this.elements.external.href = this.elements.link;
    }

    openModal() {
        window.store.dispatch({
            type: 'UPDATE_POPUP_STATE',
            popup: 'external'
        });
    }
}

export default () => { 
    [...document.querySelectorAll('[data-external-link]')].forEach(link => new External(link));
};