export default class Navigation {
    constructor() {
        this.blockSelector = '.main-navigation';
        this.container = document.querySelector(this.blockSelector);
        this.itemList = this.container.querySelector(this.blockSelector+'__list');
        this.parentItems = this.container.querySelectorAll(this.blockSelector+'__item--has-children');
        this.allItems = this.container.querySelectorAll(this.blockSelector+'__item');
        this.itemToggleSelector = this.blockSelector + '__content-wrapper';
        this.toggleSelector = this.container.querySelector(this.blockSelector + '__toggle-button');
        this.initialized = false;
        this.currentItemOpen = null;
        this.opened = false;
        this.unsubscribeInit = false;
        this.boundBodyClickHandler = this.handleBodyEventListener.bind(this);
        this.checkState();
    }

    checkState() {
        const store = window.store,
        state = store.getState();

        //ensure currentPage is set before initializing
        if (state.currentPage) {
            this.init();
            if (typeof this.unsubscribeInit === 'function') {
                this.unsubscribeInit();
            }
        } else if (!this.unsubscribeInit) {
            this.unsubscribeInit = store.subscribe(this.init.bind(this));
        }
    }

    init() {
        this.attachEvents();
        this.initialized = true;
    }

    isParent(location, id) {
        if (location.substring(0, id.length + 1) === id + '.') {
            return true;
        } else {
            return false;
        }
    }

    attachEvents() {
        const store = window.store,
        currentPage = store.getState().currentPage;
        //attach open/close event to parent menu items and set current page parent class
        for (let v = 0; v < this.parentItems.length; v++) {
            let parent = this.parentItems[v],
            itemToggle = parent.querySelector(this.itemToggleSelector),
            parentId = parent.getAttribute('data-location');

            for (let i = 0; i < this.allItems.length; ++i) {
                let item = this.allItems[i];
                //if the heirarchal location id equals currentPage, set the appropriate class
                if (item.getAttribute('data-location') === currentPage.id) {
                    item.classList.add(this.blockSelector.substr(1) + '__item--current');
                } else if (this.isParent(currentPage.id, item.getAttribute('data-location'))) { //if the heirarchal location id matches the first part of currentPage, set the appropriate class
                    item.classList.add(this.blockSelector.substr(1) + '__item--current-parent');
                }
            }
            itemToggle.addEventListener('click', (e) => {
                e.stopImmediatePropagation();
                if (store.getState().menu.itemOpen === parentId) {
                    store.dispatch({type: 'TOGGLE_MENU', menu: { itemOpen: null } });
                } else {
                    store.dispatch({type: 'TOGGLE_MENU', menu: { itemOpen: parentId }});
                }
            });
        }

        //attach open/close event to mobile toggle
        this.toggleSelector.addEventListener('click', (e) => {
            if (store.getState().menu.open) {
                store.dispatch({type: 'TOGGLE_MENU', menu: { open: false } });
            } else {
                store.dispatch({type: 'TOGGLE_MENU', menu: { open: true } });
            }
        });

        store.subscribe(this.handleStateUpdate.bind(this));
    }

    handleStateUpdate() {
        const store = window.store,
        state = store.getState();
        if (this.initialized) {
            //open close specific menu items
            if (state.menu.itemOpen) {
                let openItem = state.menu.itemOpen;
                this.closeAllItems();
                this.openItem(openItem);
            } else if (!state.menu.itemOpen) {
                this.closeAllItems();
            }

            //open/close menu (mobile)
            if (state.menu.open) {
                this.open();
            } else if (!state.menu.open) {
                this.close();
            }

            //close the menu if we move to a larger breakpoint
            if (state.currentBreakpoint !== 'small' && state.menu.open) {
                store.dispatch({ type: 'TOGGLE_MENU', menu: { open: false } });
            }
        }
    }

    open() {
        if (!this.opened) {
            //set explicit max-height to avoid overflow issues on iOS        
            setTimeout(() => { //brief delay to allow for iOS header expansion
                this.itemList.style.maxHeight = (window.innerHeight - this.container.offsetHeight)+'px';
            }, 100);
            this.container.classList.add(this.blockSelector.substr(1) + '--open');
            this.opened = true;
        }
    }

    close() {
        if (this.opened) {
            this.container.classList.remove(this.blockSelector.substr(1) + '--open');
            this.itemList.style.maxHeight = '';
            this.opened = false;
        }
    }

    openItem(locationID) {
        let item = this.container.querySelector((this.blockSelector + '__item[data-location="' + locationID + '"]'));
        item.classList.add(this.blockSelector.substr(1) + '__item--open');
        this.currentItemOpen = locationID;
        document.body.addEventListener('click', this.boundBodyClickHandler);
    }

    closeAllItems() {
        for (let v = 0; v < this.parentItems.length; v++) {
            let parent = this.parentItems[v];
            parent.classList.remove(this.blockSelector.substr(1) + '__item--open');
        }
        this.currentItemOpen = null;
        document.body.removeEventListener('click', this.boundBodyClickHandler);
    }

    handleBodyEventListener(e) {
        store.dispatch({type: 'TOGGLE_MENU', menu: { itemOpen: null } });
	}
}