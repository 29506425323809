/**
 * Creates a popup
 * 
 * @class Popup
 * @desc adds necessary functionality to a given node
 * 
 * Screenshot tool:
    window.store.dispatch({
        type: 'UPDATE_POPUP_STATE',
        popup: 1
    });
 * 
 */
class Popup {
    constructor(popup) {

        // popup id - used to explicitly bind trigger and popup
        this.id = popup.getAttribute('data-popup');

        // elements
        this.elements = {
            close: [...popup.getElementsByClassName('popup__close')],
            container: popup.getElementsByClassName('popup__container')[0],
            content: document.getElementsByClassName('content')[0],
            popup: popup,
            triggers: [...document.querySelectorAll(`[data-open-popup="${this.id}"]`)]
        };

        // modifiers
        this.modifiers = {
            content: 'content--hidden',
            noScroll: 'noScroll',
            open: 'popup--open'
        };

        // binded methods
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.show = this.show.bind(this);

        // subscribe to store updates
        window.store.subscribe(this.show);

        // init
        this.handleClick();
    }

    handleClick() {
        // add 'open popup' functionality to all triggers 
        this.elements.triggers.forEach( trigger => trigger.onclick = this.open );

        // handle close events
        this.elements.close.forEach( x => x.onclick = this.close );
    }

    show() {
        // if store popup id is equal to instance id => show popup
        // using non-strict comparison on purpose => to convert both values to same type
        // @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Comparison_Operators#Equality_operators
        if (window.store.getState().popup == this.id) {
            this.elements.popup.classList.add(this.modifiers.open);
            this.elements.content.classList.add(this.modifiers.content);
            document.body.classList.add(this.modifiers.noScroll);
        }
        // else hide popup 
        else {
            this.elements.popup.classList.remove(this.modifiers.open);
            this.elements.content.classList.remove(this.modifiers.content);            
            document.body.classList.remove(this.modifiers.noScroll);
        }
    }

    close() {
        window.store.dispatch({
            type: 'UPDATE_POPUP_STATE',
            popup: null
        });
    }

    open() {
        window.store.dispatch({
            type: 'UPDATE_POPUP_STATE',
            popup: this.id
        });
    }
}

/****************\
< * Initialize * >
\****************/

export default () => {
    // grab all popups on the page if any 
    let popups = [...document.querySelectorAll('[data-popup]')];
    
    // if no popups on the page => do nothing
    if (!popups.length) return;

    // else create new Popup for each element found
    popups.forEach(popup => new Popup(popup));
};