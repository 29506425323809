// /*
// 	 OVERLAY MARKUP
// 	----------------

// 	<a class="" href="#key">...</a>

// 	<div class="" data-key="key">...</div>
// */
const templates = require('./client-templates');
const cookies = require('./cookie');
const storage = require('./session.js');
const extend = require('./extend');

class Modal {

	/**
	 * set up the modal
	 * @param {object} element
	 * @param {object} options
	 */
	constructor(querySelector, options) {
		// assign the element
		this.querySelector = querySelector;
		this.active = false;
		this.interstitialLink = null;
		this.boundBodyClickHandler = this.handleBodyEventListener.bind(this); // bind body event handler

		// define the default settings
		this.defaults = {
			modalClassname: 'modal',
			modalExtraClassnames: null, // Array of extra classes to add to our parent '.modal'
			modalCloseSelector: '.modal__close',
			modalActiveClassname: 'js-modal--active', // active class applied to body
			modalTemplate: null, // hbs template to use as modal instead of default
			interstitial: false, // handles event binding for interstitial
			cookied: false, // enables cookie checking
			sessionStoraged: false, // enables sessionStorage checking
			cookieName: null, // cookie name if cookied
			reduxAction: 'modal',
			closeOnOutsideClick: true,
			onOpen: function (obj) { }, // function to call on open
			onClose: function (obj) { }, // function to call on close
			onClickBind: function (event) { // function called on click event of trigger element. Passes in the event and expects an object
				return {};
			},
			modalTemplateContent: null,
			modalCloseHandler: null,
			afterInsertIntoDom: null,
			registeredPartials: [],
		};

		// Force 'modalExtraClassnames' to an array (if we pass in a string)
		if (options.modalExtraClassnames && !Array.isArray(options.modalExtraClassnames)) {
			options.modalExtraClassnames = [options.modalExtraClassnames];
		}

		// overwrite settings with imported option object
		this.settings = extend({}, this.defaults, options);

		this.init();
	}

	init() {
		const store = window.store;

		store.subscribe(this.handleStoreUpdates.bind(this));


		if (this.settings.registeredPartials) {
			const partialsRegistrationObj = {};

			this.settings.registeredPartials.forEach((partialName) => {
				partialsRegistrationObj[`base/shared/${partialName}`] = templates[`${partialName}`];
			});

			templates.handlebars.registerPartial(partialsRegistrationObj);
		}

		if (this.settings.cookied || this.settings.sessionStoraged) {
			let cookiedVisit;
			if (this.settings.cookied) {
				cookiedVisit = cookies.get(this.settings.cookieName);
			} else if (this.settings.sessionStoraged) {
				cookiedVisit = storage.sessionStorage.getItem(this.settings.cookieName)
			}

			if (!cookiedVisit) {
				store.dispatch({
					type: 'SET_MODAL_STATE',
					modal: {
						active: true,
						template: this.settings.modalTemplate,
						cookie: 1, // setting cookie to 1
						content: this.settings.modalTemplateContent
					}
				});
			}
		} else {
			this.elements = document.querySelectorAll(this.querySelector);

			[...this.elements].forEach((element) => {
				element.addEventListener('click', this.handleClickEvent.bind(this));
			});
		}
	}

	handleClickEvent(event) {
		const store = window.store;
		const content = null;

		event.preventDefault();
		event.stopPropagation();

		const defaultDispatchObj = {
			active: true,
			template: this.settings.modalTemplate,
			content: null
		};

		let customDispatchObj = this.settings.onClickBind(event); // returns an obj
		let returnObj = {};

		if (typeof customDispatchObj !== 'object' && typeof extendObj === null) customDispatchObj = {};

		returnObj = extend(defaultDispatchObj, customDispatchObj);

		const dispatchObj = {
			type: 'SET_MODAL_STATE',
			modal: returnObj
		};

		store.dispatch(dispatchObj);
	}

	handleStoreUpdates() {
		const store = window.store;
		const currentState = store.getState();
		const isCurrentModal = (this.settings.modalTemplate === currentState.modal.template);

		// if not active and current state active, open
		if (!this.active && currentState.modal.active && isCurrentModal) this.open(currentState.modal);

		// if active and current state inactive, close
		if (this.active && !currentState.modal) this.close();
	}


	/**
	 * open the modal
	 * @param {object} currentState
	 */
	open(currentState) {
		const bodyElement = document.body;
		const modalName = this.settings.modalTemplate || 'default-modal';

		this.active = true;

		// set the modal content
		this.modalContent = templates[modalName](currentState);

		// if cookied, create the cookie
		if (this.settings.cookied) {
			cookies.create(this.settings.cookieName, 1);
		} else if (this.settings.sessionStoraged) {
			storage.sessionStorage.setItem(this.settings.cookieName, 1);
		}

		// append modal to the body
		bodyElement.classList.add(this.settings.modalActiveClassname);

		// listen to non-modal clicks
		if (this.settings.closeOnOutsideClick) {
			bodyElement.addEventListener('click', this.boundBodyClickHandler);
			bodyElement.addEventListener('touchstart', this.boundBodyClickHandler);
		}

		// add modal to the body
		bodyElement.appendChild(this.modal);

		if (typeof this.settings.afterInsertIntoDom === 'function') {
			this.settings.afterInsertIntoDom(this.modal);
		}

		// call the open callback
		this.settings.onOpen(this);
	}

	/**
	 * close the modal
	 */
	close({ fadeOut = false, keepBodyActiveClassnameAfterNodeRemoval = false } = {}) {
		this.active = false;

		const bodyElement = document.body;

		// remove the body click event
		if (this.settings.closeOnOutsideClick) {
			bodyElement.removeEventListener('click', this.boundBodyClickHandler);
			bodyElement.removeEventListener('touchstart', this.boundBodyClickHandler);
		}

		if (fadeOut) {
			this.modal.classList.add('modal--fadout');

			// I should be using `transitionend` but this wasn't working for some reason, so 200ms delay below also works but should be kept in sync with '_modal.scss'
			setTimeout(() => {
				if (!keepBodyActiveClassnameAfterNodeRemoval) {
					bodyElement.classList.remove(this.settings.modalActiveClassname);
				}

				bodyElement.removeChild(this.modal);
				this.settings.onClose(this);
			}, 200);
		} else {
			// remove the active class from the body
			if (!keepBodyActiveClassnameAfterNodeRemoval) {
				bodyElement.classList.remove(this.settings.modalActiveClassname);
			}

			// remove the modal from the body
			bodyElement.removeChild(this.modal);
			this.settings.onClose(this);
		}
	}

	/**
	 * Set the modal
	 * @param {object} modalContent - node of modal content
	 */
	set modalContent(modalContent) {
		const store = window.store;
		// create the modal element
		const modalElement = document.createElement('div');

		// get the modal content
		const returnModalContent = (typeof modalContent === 'string') ? modalContent : modalContent.innerHTML;

		// add classname to modal
		modalElement.classList.add(this.settings.modalClassname);

		if (Array.isArray(this.settings.modalExtraClassnames) && this.settings.modalExtraClassnames.length) {
			modalElement.classList.add(...this.settings.modalExtraClassnames);
		}

		// add the markup to the modal
		modalElement.innerHTML = returnModalContent;

		// add close event to close button(s). The default is to close the modal, unless you pass a separate `modalCloseHandler` option.
		const modalClose = modalElement.querySelectorAll(this.settings.modalCloseSelector);
		let modalCloseHandler = () => {
			store.dispatch({
				type: 'SET_MODAL_STATE',
				modal: false
			});
		};

		if (typeof this.settings.modalCloseHandler === 'function') {
			modalCloseHandler = this.settings.modalCloseHandler;
		}

		modalClose.forEach((element) => {
			element.addEventListener('click', modalCloseHandler);
		});

		// set the modal
		this.modal = modalElement;
	}

	handleBodyEventListener(event) {
		if (!this.modal.contains(event.target)) {
			event.stopPropagation();
			event.preventDefault();

			window.store.dispatch({
				type: 'SET_MODAL_STATE',
				modal: false
			});
		}
	}
}

module.exports = function modal(selector, options) {
	return new Modal(selector, options);
};
