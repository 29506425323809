module.exports = function throttle(func, limit) {
    let inThrottle = undefined;
    return function() {
      var args = arguments,
        context = this;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        return setTimeout(function() {
          return inThrottle = false;
        }, limit);
      }
    };
  };