function getScript(source, callback) {
	let script = document.createElement('script');
	const prior = document.getElementsByTagName('script')[0];
	const stateChange = function stateChange(_, isAbort) {
		if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
			script.onload = null;
			script.onreadystatechange = null;
			script = undefined;

			if (!isAbort) {
				if (callback) callback();
			}
		}
	};
	script.async = 1;

	script.onload = stateChange;
	script.onreadystatechange = stateChange;

	script.src = source;
	prior.parentNode.insertBefore(script, prior);
}

module.exports = getScript;
