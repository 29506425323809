import tippy from 'tippy.js';
class Tooltip{
    constructor(){
        this.elements = {
            icon:document.querySelectorAll('.tippy')
        };
        this.tipify();
    }
    
    tipify() {
        tippy(".tippy", {
          interactive: true,
          arrow: true,
          position: "bottom",
          trigger: "mouseenter",
          flipDuration: 0,
          offsetY: 0,
          appendTo: document.querySelector(".body-wrap"),
          popperOptions: {
            modifiers: {
              flip: {
                behavior: ["bottom-start", "bottom-end"]
              },
              preventOverflow: {
                enabled: true
              }
            }
          }
        });
    }
  }

module.exports = function toolTip(){
    return new Tooltip();
}
  