class HelpDropDown {
    
    constructor(dropDownTitleSelector, helpDropDownSelector, activeClass){
      this.dropDownTitleSelector = dropDownTitleSelector;
      this.helpDropDownSelector = helpDropDownSelector;
      this.dropDownTitle = document.querySelector(this.dropDownTitleSelector);
      this.helpDropDown = document.querySelector(helpDropDownSelector);
      this.activeClass = activeClass;
      this.updateUI = this.updateUI.bind(this);

      this.isOpen = false; // default 'false' => `closed`

      this.init();
    }    

    init() {
      if(this.helpDropDown) {
        this.dropDownTitle.addEventListener("click", this.openDropDown);
        store.subscribe(this.updateUI);
      }
    }
    
    openDropDown(){ 
      // update global state
      store.dispatch({
        type: "TOGGLE_HELP_DROPDOWN",
        helpDropDown: {
          isOpen: this.isOpen ? false : true
        }
      });
      // update local state
      this.isOpen = this.isOpen ? false : true;
    }
    
    
    // update UI when dispatch is called 
    updateUI() {
       
      if(store.getState().helpDropDown.isOpen) {
        setTimeout(_=> { // put it to the end of event que
          this.helpDropDown.classList.add(this.activeClass);
        }, 0);
      }
      else {
        setTimeout(_ => { // put it to the end of event que
          this.helpDropDown.classList.remove(this.activeClass);
        }, 0);
      }
    }
  }

export default function init() {
  // constant variables 
  const activeClass = "open";
  const helpDropDownSelector = ".help-dropdown";
  const dropDownTitleSelector = ".help-dropdown__title";

   // create class instance 
  let helpDropDown = new HelpDropDown(dropDownTitleSelector, helpDropDownSelector, activeClass);
}