import debounce from './debounce';
class CardFlip {

    constructor(){
      this.elements = {
        card:document.querySelectorAll('.card')
      };
      //bind methods
      this.updateTheUi = this.updateTheUi.bind(this);
      this.removeSecondaryClass = this.removeSecondaryClass.bind(this);
      this.determine = this.determine.bind(this);
      this.attachHandler = this.attachHandler.bind(this);
      this.debounceTime = 400;
      this.init();
    }
    //Actions
    init(){
        const store = window.store;
        store.subscribe(this.determine);
        this.attachHandler();
    }/**
     * Actions to pass arguments to state
     * @param {Boolean} value 
     * @param {Number} index 
     */
    flipCard(value, index){
      return { type: 'FLIP_CARD', value, index }
    }/**
     * Determines the state of the UI and runs Update UI Function
     */
    determine() {
      if(store.getState().cards.status){
          this.updateTheUi(store.getState().cards.index);
      }
        if(!store.getState().cards.status){
          this.removeSecondaryClass();
      }
    }/**
     * Adds the 'flip' or 'rev' class based on index or current state of flip
     * True = faceup |  False = facedown
     * @param {Number} index
     */
    updateTheUi(index) {
      //remove all flip states
      if(!this.elements.card[index].classList.contains('flip')) {
          for(let i = 0; i < this.elements.card.length; i ++) {
              if (this.elements.card[i].classList.contains('flip')){
                this.elements.card[i].classList.remove('flip');
                this.elements.card[i].classList.add('rev');
              }
          }
        this.elements.card[index].classList.add('flip');
      } else if (this.elements.card[index].classList.contains('flip')) {
          this.elements.card[index].classList.remove('flip');
          this.elements.card[index].classList.add('rev');
      }
    }
    removeSecondaryClass() {
      for(let i = 0; i < this.elements.card.length; i ++) {
        this.elements.card[i].classList.remove('rev');
      } 
    }/**
     * Attaches Cick and Animation End Event Handlers to '.card'
     */
    attachHandler() {
        let self = this;
        let cards = self.elements.card;
        for(let i = 0; i < [...cards].length; i++){
            [...cards][i].addEventListener('click', debounce(function(){
                store.dispatch(self.flipCard(true, i));
            },self.debounceTime));
            [...cards][i].addEventListener('animationend', debounce(function(){
                store.dispatch(self.flipCard(false, i));
            },self.debounceTime));
        }
    }
}
module.exports = function cardFlip(){
    return new CardFlip();
}

  
  