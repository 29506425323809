class ShowHoverText{
  constructor(){
      this.elements = {
          bodyEl:document.getElementsByTagName('body'),
          icon:document.querySelectorAll('.icon__wrapper'),
          textContainer:document.querySelector('.show-text__content'),
          imageWrapper:'.icon__img-wrapper',
          selectIconText: `<span class="show-text__content--inner">Select an icon above</span>`
      };
    this.attachListeners = this.attachListeners.bind(this);
    this.checkIfSelected = this.checkIfSelected.bind(this);
    this.displayText = this.displayText.bind(this);
    this.emptyText = this.emptyText.bind(this);
    this.init();
  }
  init(){
    this.attachListeners();
    const store = window.store;
    store.subscribe(this.checkIfSelected);

  }/**
   * Action Creator for state
   * @param {Boolean} selected 
   * @param {String} text 
   * @param {Number} index 
   */
  showText(selected, text, index){
    return { type: 'SET_HOVER_TEXT', selected, text, index }
  }/**
   * Subscribe function that runs action
   */
  checkIfSelected(){
    let self = this;
    if(store.getState().show.selected){
        self.displayText();
    } else {
        self.emptyText();
    }
  }/**
   * Displays text and add active state for screenshot purposes
   */
  displayText(){
    let self = this;
    [...self.elements.icon].map(element =>{
        element.querySelector(self.elements.imageWrapper).classList.remove('active');
    });
    self.emptyText();
    self.elements.textContainer.innerHTML = store.getState().show.text;
    [...self.elements.icon][store.getState().show.index].querySelector(self.elements.imageWrapper).classList.add('active');
  }/**
   * Emptys/Hides text and removes active state for screenshot purposes
   */
  emptyText(){
      let self = this;

        [...self.elements.icon].map(element =>{
            element.querySelector(self.elements.imageWrapper).classList.remove('active');
        });

        self.elements.textContainer.innerHTML = self.elements.selectIconText;
  }/**
   * Attaches event listeners "mouseouve" and "mouseout" to element
   * reads data attribute from elements and passes into state
   */
  attachListeners(){
    let self = this;
        for (let i = 0; i< [...self.elements.icon].length; i++) {
            //Desktop Interaction
            [...this.elements.icon][i].addEventListener('click', function(e){
                e.stopPropagation();
                let fillText = this.getAttribute('data-text');
                store.dispatch(self.showText(true, fillText, i));
            });
            //Click to escape
        } 
        document.getElementById('page-wrapper').addEventListener('click', function(event){
            if(!event.target.classList.contains('icon__wrapper')){
                store.dispatch(self.showText(false, null, null));
            }
        });
    }
}
module.exports = function showHoverText(){
    return new ShowHoverText();
}

//mobile screen functionality
  let icons = document.querySelectorAll('.icon-wrapper')

  icons.forEach((icon, index) => {

    icon.addEventListener('click', function(event){
      
      if(icon.classList.contains('icon-wrapper--active')) {
        icon.classList.remove('icon-wrapper--active')
      } else {
        icons.forEach(element => element.classList.remove('icon-wrapper--active'));
        icon.classList.add('icon-wrapper--active')
      }
    })
  })