class Bubbles {
    constructor(bubbles, index) {                       // 'bubbles' - container for Bubbles instance
        this.name = `bubbles-${index}`;

        this.selectors = {
            buttons: 'button',                          // navigation buttons
            activeBtn: 'button--active',                // btn's active state modifier
            imgMobile: 'bubbles__img--mobile-initial',  // mobile images
            imgMobileAlt: 'bubbles__img--mobile',       // mobile images selector if initial not found
            imgDesktop: 'bubbles__img--desktop',        // desktop images
            navigation: 'bubbles__navigation',          // navigation container => will be used to set min-height for desktop
            subButtons: 'bubbles__sub-trigger',         // sub slide triggers
            activeSlide: 'bubbles__slide--active',      // slide's active state modifier
            slidesMobile: 'bubbles__slide--mobile',     // mobile slides
            slidesDesktop: 'bubbles__slide--desktop',   // desktop slides
            slidesContainer: 'bubbles__slides-wrapper', // desktop slides' container
        };

        this.elements = {
            block: bubbles,                                                               // top block component
            navigation: bubbles.getElementsByClassName(this.selectors.navigation),        // nav container
            buttons: bubbles.getElementsByClassName(this.selectors.buttons),              // nav elements
            subButtons: bubbles.getElementsByClassName(this.selectors.subButtons),        // sub slide triggers
            mobileSlides: bubbles.getElementsByClassName(this.selectors.slidesMobile),    // mobile slides
            desktopSlides: bubbles.getElementsByClassName(this.selectors.slidesDesktop),  // desktop slides
            desktopContainer: bubbles.getElementsByClassName(this.selectors.slidesContainer),  // desktop slides            
            mobileImages: bubbles.getElementsByClassName(this.selectors.imgMobile).length ?     // sophisticated ternary
                bubbles.getElementsByClassName(this.selectors.imgMobile) : 
                bubbles.getElementsByClassName(this.selectors.imgMobileAlt),       // mobile images
            desktopImages: bubbles.getElementsByClassName(this.selectors.imgDesktop)      // desktop images
        };
        
        // set default local state 
        this.state = {
            current: 0,
            prev: 0,
            subslide: -1, // subslides indexes starts with 0
            screen: window.innerWidth
        };

        this.init();
    }

    /*************\
    < * METHODS * >
    \*************/

    init() {

        // add onclick events for navigation
        this.updateUI();

        // set min-height
        this.minHeight();

        // set default slide to 0
        this.showSlide(0);
        
        // set initial global state
        this.storeUpdate(0); 
        
        // subscribe to get updates on active slide index
        window.store.subscribe(this.onStoreUpdate.bind(this));

        // catch window resize
        window.addEventListener('resize', () => {
            // escape vertical window resize triggering (iOS specifically)
            if (this.state.screen === window.innerWidth) return;
            else this.state.screen = window.innerWidth;

            this.minHeight();
            this.clearSlides();
            this.showSlide(this.state.current, this.state.subslide);
        });
    }

    updateUI() {
        // main navigation
        [...this.elements.buttons].forEach((elm, index) => {
            elm.addEventListener('click', event => this.handleNavigationClick.call(this, event, index));
        });
        // subslide triggers (if any)
        [...this.elements.subButtons].forEach( elm => {
            elm.addEventListener('click', event => this.handleTriggerClick.call(this, elm, event));
        }); 
        // slides click handling ¯\_(ツ)_/¯ required by frs
        [...this.elements.desktopSlides].forEach( (elm, index) => {
            elm.addEventListener('click', () => this.handleSlideClick.call(this, index));
        });
        [...this.elements.mobileSlides].forEach( (elm, index) => {
            elm.addEventListener('click', () => this.handleSlideClick.call(this, index));
        });
    }

    handleNavigationClick(event, index) {
        event.preventDefault();

        // update global state
        this.storeUpdate(index);
    }

    handleTriggerClick(elm, event) {
        event.preventDefault();
        event.stopPropagation();    // subslide container has it's own listener
        
        let subindex = elm.getAttribute('data-subslide') || -1;

        // update global state
        this.storeUpdate(this.state.current, subindex);
    }

    handleSlideClick(index) {
        this.storeUpdate(index);
    }

    storeUpdate(index, subindex = -1) {
        window.store.dispatch({ 
            type: 'SET_BUBBLE_SLIDE_INDEX', 
            bubble: {
                [this.name]: {
                    slide: index,
                    subslide: subindex
                }
            }
        });
    }

    showSlide(index, subindex = -1) {
        // update class modifiers
        this.elements.mobileSlides[index].classList.add(this.selectors.activeSlide);
        this.elements.desktopSlides[index].classList.add(this.selectors.activeSlide);
        this.elements.buttons[index].classList.add(this.selectors.activeBtn);

        // update slide height
        this.elements.mobileSlides[index].style.height = `${this.elements.mobileImages[index].offsetHeight}px`;
        this.elements.desktopSlides[index].style.height = `${this.elements.desktopImages[index].offsetHeight}px`;

        // update subindex data
        this.elements.block.setAttribute('data-subslide', subindex); 
    }

    clearSlides() {
        [...this.elements.mobileSlides, 
            ...this.elements.desktopSlides].forEach(slide => {
                // clear class modifiers
                slide.classList.remove(this.selectors.activeSlide);
                // clear height of elements
                slide.style.height = 0;
            });
        
        [...this.elements.buttons].forEach(btn => btn.classList.remove(this.selectors.activeBtn));
    }

    updateState(index, subindex = 0) {
        // update local state
        this.state.prev = this.state.current;
        this.state.current = index;
        this.state.subslide = subindex;
    }

    minHeight() {
        // check navigation and desktop images height values and pick MAX one (add 15 for a better effect)
        let max = Math.max.apply(0, [...this.elements.navigation, ...this.elements.desktopImages].map(elm => elm.offsetHeight + 15));
        this.elements.desktopContainer[0].style.minHeight = `${max}px`;
    }
    
    onStoreUpdate() {
        let index = Number(window.store.getState().bubble[this.name]['slide']),
            subindex = Number(window.store.getState().bubble[this.name]['subslide']);

        // check if it's NaN or global index is equal to local
        if (isNaN(index) || (index === this.state.current && subindex === this.state.subslide)) return;

        this.clearSlides();
        this.showSlide(index, subindex);

        // update local state
        this.updateState(index, subindex);
    }
}

/******************\
< * Init Bubbles * >
\******************/

export default () => [...document.getElementsByClassName('bubbles')].forEach((bubble, index) => {
    new Bubbles(bubble, index);
});