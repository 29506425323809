export default class Sitemap {
	constructor() {
		this.pages = [
			{
				'id': '1.0',
				'title': 'Home',
				'siteSection': 'root',
				'path': '/'
			},
			{
				"id": "2.1",
				"title": "Prepare for Infusions",
				"section": "your-treatment",
				"path": "/prepare-for-infusions/"
			},
			{
				"id": "2.2",
				"title": "Pack a Bag",
				"section": "your-treatment",
				"path": "/pack-a-bag/"
			},
			{
				"id": "2.3",
				"title": "Track Your Symptoms",
				"section": "your-treatment",
				"path": "/track-your-symptoms/"
			},
			{
				"id": "2.4",
				"title": "Cancer Concerns",
				"section": "your-treatment",
				"path": "/cancer-concerns/"
			},
			{
				"id": "2.5",
				"title": "Types of Support",
				"section": "your-treatment",
				"path": "/types-of-support/"
			},
			{
				"id": "3.0",
				"title": "Talk With Your Health Care Team",
				"section": "talking-about-cancer",
				"path": "/talk-with-your-health-care-team/"
			},
			{
				"id": "3.1",
				"title": "Talk With Your Family",
				"section": "talking-about-cancer",
				"path": "/talk-with-your-family/"
			},
			{
				"id": "3.2",
				"title": "Caregiver Listening",
				"section": "talking-about-cancer",
				"path": "/listening-for-caregivers/"
			},
			{
				"id": "3.3",
				"title": "Discuss Tough Topics",
				"section": "talking-about-cancer",
				"path": "/discuss-tough-topics/"
			},
			{
				"id": "3.4",
				"title": "What to Ask Your Doctor",
				"section": "talking-about-cancer",
				"path": "/what-to-ask-your-doctor/"
			},
			{
				"id": "4.0",
				"title": "Find Balance",
				"section": "physical-health",
				"path": "/finding-balance/"
			},
			{
				"id": "4.0.1",
				"title": "Find Balance - Results",
				"section": "physical-health",
				"path": "/finding-balance-results/"
			},
			{
				"id": "4.1",
				"title": "Sleep Better",
				"section": "physical-health",
				"path": "/sleep-better/"
			},
			{
				"id": "4.2",
				"title": "Eat Healthy",
				"section": "physical-health",
				"path": "/eat-healthy/"
			},
			{
				"id": "4.3",
				"title": "Use Energy Wisely",
				"section": "physical-health",
				"path": "/use-energy-wisely/"
			},
			{
				"id": "4.4",
				"title": "Managing Meals",
				"section": "physical-health",
				"path": "/managing-meals/"
			},
			{
				"id": "5.0",
				"title": "Manage Stress",
				"section": "emotional-health",
				"path": "/manage-stress/"
			},
			{
				"id": "5.1",
				"title": "Relaxation Videos",
				"section": "emotional-health",
				"path": "/relaxation-videos/"
			},
			{
				"id": "5.2",
				"title": "Practice Calm Breathing",
				"section": "emotional-health",
				"path": "/practice-calm-breathing/"
			},
			{
				"id": "5.3",
				"title": "Be Kind to Yourself",
				"section": "emotional-health",
				"path": "/be-kind-to-yourself/"
			},
			{
				"id": "5.4",
				"title": "Notes of Kindness",
				"section": "emotional-health",
				"path": "/notes-of-kindness/"
			},
			{
				"id": "5.5",
				"title": "Reduce Stress Activity",
				"section": "emotional-health",
				"path": "/reduce-stress-activity/"
			},
			{
				"id": "5.6",
				"title": "Change Your Thoughts",
				"section": "emotional-health",
				"path": "/change-your-thoughts/"
			},
			{
				"id": "5.7",
				"title": "Distance Bad Thoughts",
				"section": "emotional-health",
				"path": "/distance-bad-thoughts/"
			},
			{
				"id": "6.0",
				"title": "Create Your Care Tean",
				"section": "cancer-resources",
				"path": "/create-your-care-team/"
			},
			{
				"id": "6.1",
				"title": "Helpful Organizations",
				"section": "cancer-resources",
				"path": "/helpful-organizations/"
			},
			{
				"id": "6.2",
				"title": "Cancer FAQs",
				"section": "cancer-resources",
				"path": "/cancer-faqs/"
			},
			{
				"id": "6.3",
				"title": "Downloads",
				"section": "cancer-resources",
				"path": "/downloads/"
			}
		];
		this.init();
	}

	init() {
		for (var i = 0, iMax = this.pages.length; i < iMax; i++) {
			if (this.pages[i].path && (window.location.pathname === this.pages[i].path || window.location.pathname === this.pages[i].path.slice(0, -1))) {
				window.store.dispatch({ type: 'SET_CURRENT_PAGE', currentPage: this.pages[i] });
				break;
			} else {
				window.store.dispatch({ type: 'SET_CURRENT_PAGE', currentPage: { id: "0", title: 'PAGE_NOT_FOUND' } });
			}
		}
	}
}