/*
	* OVERLAY *

	No markup required. Using redux to set overlay: 'true', will display the overlay.
	Setting the state of overlay to 'false' will close the overlay.

*/
const extend = require('./extend');

class Overlay {

	/**
	 * set up the overlay
	 * @param {object} element
	 * @param {object} options
	 */
	constructor(element, options) {
		// assign the element
		this.element = element;

		// define the default settings
		this.defaults = {
			overlayClassname: 'overlay', // classname applied to the overlay, used to set additional styles.
			overlayActiveClassname: 'js-overlay--active', // active class applied to body, used to set additional styles,
			reduxStore: 'overlay',
			zIndex: 300
		};

		this.active = false;
		this.globalStore = null;

		this.fixedScrollPosition = 0;

		// overwrite settings with imported option object
		this.settings = extend({}, this.defaults, options);

		this.init();
	}

	init() {
		// create the overlay
		this.createOverlayContent();

		// subscribe to state updates
		window.store.subscribe(this.handleStateChange.bind(this));
	}

	// on state change, toggle the overlay
	handleStateChange() {
		const store = window.store;
		const currentState = store.getState();

		this.globalStore = currentState;


		// check if the zIndex has changed
		const newZIndex = (this.globalStore.overlay.zIndex) ? this.globalStore.overlay.zIndex : this.settings.zIndex;

		// list for state as true and not already active
		if (this.globalStore[this.settings.reduxStore] && (!this.active || (this.active && this.zIndex !== newZIndex))) {
			this.open();
		}

		// list for state as false and already active
		if (!this.globalStore[this.settings.reduxStore] && this.active) {
			this.close();
		}
	}

	/**
	 * open the overlay
	 */
	open() {
		const bodyElement = document.body;
		const yOffset = (window.pageYOffset || bodyElement.scrollTop);
		const clientTop = (bodyElement.clientTop || 0);
		const otherOverlays = bodyElement.getElementsByClassName(this.settings.overlayClassname);

		// set to active
		this.active = true;

		// dynamically set the z-index
		this.zIndex = (this.globalStore.overlay.zIndex) ? this.globalStore.overlay.zIndex : this.settings.zIndex;

		// // set scroll position
		this.fixedScrollPosition = yOffset - clientTop;

		// // remove other overlay's
		for (let i = 0; i < otherOverlays.length; i++) {
			let child = otherOverlays[i];
			child.parentNode.removeChild(child);
		}

		// // append overlay to the body
		bodyElement.classList.add(this.settings.overlayActiveClassname);

		/**
		 * Using a class for `overflow: hidden` in place of an inline
		 * style because OneTrust CCPA Cookie banner blows away our inline
		 * styles (!!)
		 */
		bodyElement.classList.add('js--scroll-lock');

		// set the zIndex
		this.overlay.setAttribute('style', `z-index: ${this.zIndex}`);

		// append the overlay to the body
		bodyElement.appendChild(this.overlay);
	}

	/**
	 * close the overlay
	 */
	close() {
		const bodyElement = document.body;

		// set active to false
		this.active = false;

		// remove overlay from the body
		bodyElement.classList.remove(this.settings.overlayActiveClassname);
		bodyElement.classList.remove('js--scroll-lock');
		bodyElement.removeChild(this.overlay);

		// scroll to previous position
		// @note no longer used since we are just using `overflow: hidden` trick
		// window.scrollTo(0, this.fixedScrollPosition);

		// reset the fixed scroll position
		this.fixedScrollPosition = 0;
		this.lastZIndex = null;
	}

	/**
	 * Set the overlay
	 * @param {object} overlayContent - node of overlay content
	 */
	createOverlayContent() {
		// create the overlay element
		const overlayElement = document.createElement('div');

		// add classname to overlay
		overlayElement.classList.add(this.settings.overlayClassname);


		// add the markup to the overlay
		overlayElement.innerHTML = '&nbsp;';

		// set the overlay
		this.overlay = overlayElement;
	}
}

module.exports = function overlay(options) {
	return new Overlay(options);
};
