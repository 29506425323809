require("dotenv").config();
// require("../../../.env");

const DEFAULT_POSTAL_CODE = "00000";
const DEFAULT_ANSWER = "X";
const REGEX_REMOVE_QUERY_PARAMS = /(\?|\&)[^=]+=[^=&]+/g;
const REGEX_REMOVE_TRAILING_SLASH = /\/$/;
const EMPTY_STRING = "";
const REDIRECT_PAGE = "/thank-you/";
const CONTENT_PARENTHESIS = /\(([^)]+)\)/;

const PRODUCTION_SITE_REFERER_URL = "www.yoursupportresource.com";
const PRODUCTION_API_KEY = "5383f00c-1bed-45fb-b9b9-819a0c8dcd14";
const PRODUCTION_MERCK_API_KEY = "Ct1GdrErjR0pqm9GrbyUMdi9FopUOpZt";
const PRODUCTION_ENDPOINT = "https://www.omniapi-prod.com/omni/v1/rmi/create";

const STAGE_SITE_REFERER_URL =
  "yoursupportresource-awscf-stage.epublishmerck.com/";
const STAGE_API_KEY = "122df710-4488-41d8-af4d-7d5d6c40363c";
const STAGE_MERCK_API_KEY = "OrQgDtMzEwV3F5aG4LX5o9nV6v5wKHG1";
const STAGE_ENDPOINT = "https://www.omniapi-test.com/omni/v1/rmi/create";

class Form {
  constructor() {
    this.selectors = document.querySelectorAll(".range-selector");
    this.questions = document.getElementsByClassName("question-container");
    this.submitButton = document.getElementById("submit-button");
    this.init(this.selectors, this.questions);
  }

  init(selectors, questions) {
    let self = this;
    this.isSurveyTwo(selectors);

    this.submitButton.addEventListener("click", function (e) {
      e.preventDefault();
      const data = self.dataHandler();
      const isProduction = self.getEnvironment();

      const URL = isProduction ? PRODUCTION_ENDPOINT : STAGE_ENDPOINT;
      const siteURL = isProduction
        ? PRODUCTION_SITE_REFERER_URL
        : STAGE_SITE_REFERER_URL;
      const apiKEY = isProduction ? PRODUCTION_API_KEY : STAGE_API_KEY;
      const merckApiKey = isProduction
        ? PRODUCTION_MERCK_API_KEY
        : STAGE_MERCK_API_KEY;

      self.postHandler(data, URL, apiKEY, siteURL, merckApiKey);
    });
  }

  getEnvironment() {
    let environment = document
      .getElementById("submit-button")
      .getAttribute("data-eby");
    return environment === "true" ? true : false;
  }

  //data handler
  dataHandler() {
    let form = document.querySelector("form");
    const formInputs = form.elements;
    function trimString(input, defaultValue) {
      if (typeof input === "string" && input.trim() !== "") {
        return input.trim();
      } else {
        return defaultValue;
      }
    }

    const formName = trimString(
      formInputs["formname"] ? formInputs["formname"].value : "formname",
      "formname"
    );
    const contact_materials = trimString(
      formInputs["contact_materials"]
        ? formInputs["contact_materials"].value
        : DEFAULT_ANSWER,
      DEFAULT_ANSWER
    );
    const contact_list = trimString(
      formInputs["contact_list"]
        ? formInputs["contact_list"].value
        : DEFAULT_ANSWER,
      DEFAULT_ANSWER
    );
    const postalCode = trimString(
      formInputs["postalCode"]
        ? formInputs["postalCode"].value
        : DEFAULT_POSTAL_CODE,
      DEFAULT_POSTAL_CODE
    );

    const questions = {};
    const textQuestions = [];
    var cleanName = (value) => CONTENT_PARENTHESIS.exec(value).pop();

    for (const userInput of formInputs) {
      if (userInput.type === "radio" && userInput.checked) {
        textQuestions.push(`${userInput.name}`);
        questions[cleanName(userInput.name)] = [userInput.value];
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const prospect_id = urlParams.get("prospect_id");
    questions["PROSPECT_ID"] = prospect_id ? [prospect_id] : [EMPTY_STRING];

    const data = {
      formname: formName,
      reqf: `firstName, lastName, refererUrl, contact_list, contact_materials, question(PROSPECT_ID), ${textQuestions}`,
      firstName: DEFAULT_ANSWER,
      lastName: DEFAULT_ANSWER,
      postalCode: postalCode,
      contact_list: contact_list,
      contact_materials: contact_materials,
      questions: questions,
    };

    return data;
  }

  postHandler(data, url, apiKEY, siteURL, merkApiKey) {
    fetch(url, {
      method: "POST",
      headers: {
        "x-api-key": apiKEY,
        "x-merck-apikey": merkApiKey,
        "site-referer": siteURL,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status !== 200) {
          console.error("Error:", response.status);
          throw new Error(
            "Failed to submit form. Status code: " + response.status
          );
        }
        return response.json();
      })
      .then((data) => {
        const baseURL = window.location.href.replace(
          REGEX_REMOVE_QUERY_PARAMS,
          EMPTY_STRING
        );
        window.location.href =
          baseURL.replace(REGEX_REMOVE_TRAILING_SLASH, EMPTY_STRING) +
          REDIRECT_PAGE;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  // //radio button state change detection
  // stateChangeDetect(selectors) {
  //     let self = this;
  //     selectors.forEach(rawInput => {
  //         rawInput.addEventListener('change', () => {
  //             //update hidden values to send to tridion
  //             self.updateHidden(rawInput, rawInput.value)
  //         })
  //     })
  // }

  //check if we are on  survey 2 then run
  // toggleOn function and liRemover function
  isSurveyTwo(selectors) {
    //only survey 2 has more than 42 radio buttons (selectors)
    if (selectors[43]) {
      return this.toggleOn(selectors), this.liRemover(selectors[52]);
    } else {
      return false;
    }
  }

  //toggle on phone questions
  toggleOn(selectors) {
    //extract radio inputs from phoneSupportQuestion
    let phoneRadioYes = selectors[52];
    let phoneRadioNo = selectors[53];
    let likely = document.querySelectorAll(".likely");

    //event listener for the phone buttons
    phoneRadioYes.addEventListener("click", () => {
      phoneRadioYes.classList.add("changed");
      likely.forEach((item) => {
        item.classList.add("remove"), (item.style.backgroundColor = "white");
      });
      this.liRemover(phoneRadioYes);
    });

    //remove the changed class from phoneRadioYes
    phoneRadioNo.addEventListener("click", () => {
      phoneRadioYes.classList.remove("changed");
      likely.forEach((item) => {
        item.classList.remove("remove"),
          (item.style.backgroundColor = "#e7eef1");
      });
      this.liRemover(phoneRadioYes);
    });
  }

  //remove li tags depending on phone radio question state
  //if radio question(selector[52]) do not have a "changed" class add a "hidden" class
  //to elements with  "li-remover" class
  liRemover(phoneRadioYes) {
    let tagsToRemove = document.querySelectorAll(".li-remover");
    //get question-list__item last child

    if (phoneRadioYes.classList.contains("changed")) {
      return tagsToRemove.forEach((item) => {
        item.classList.remove("hidden");
      });
    } else {
      return tagsToRemove.forEach((item) => {
        item.classList.add("hidden");
      });
    }
  }

  // setValues(question) {
  //     for (let i = 0; i < question.length; i++) {
  //         question[i].value = 55
  //         question[i].setAttribute('step', 0.01)
  //     }
  // }

  // updateHidden(question, value) {
  //     let id = question.id
  //     let answer = value
  //     //try {
  //     //    document.getElementById(question.id + "-checkbox").checked = false;
  //     //} catch (e) {
  //     //}
  //     document.getElementById(id + '-hidden').value = answer;
  //     // console.log('id', id, "answer", value)
  // }

  validate(selectors) {
    let validated = true;

    //[...selectors].forEach(selector => {
    //    if (!selector.classList.contains('hidden-selector')) {
    //        let check = document.getElementById(selector.id + "checkbox");
    //        if (!selector.classList.contains('changed')) {
    //            document.getElementById(selector.id + "-error").classList.add('show');
    //            validated = false;
    //        } else if (check == true) {
    //            validated = true;
    //        } else {
    //            document.getElementById(selector.id + "-error").classList.remove('show');
    //        }
    //    }
    //})

    //try {

    //    if (validated == false) {
    //        document.getElementsByClassName('global-error')[0].classList.add('show');
    //        document.getElementsByClassName('global-error')[1].classList.add('show');
    //    } else {
    //        document.getElementsByClassName('global-error')[0].classList.remove('show');
    //        document.getElementsByClassName('global-error')[1].classList.remove('show');
    //    }
    //} catch (e) {

    //}

    return validated;
  }
}

module.exports = function form() {
  return new Form();
};
