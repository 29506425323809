import { createStore } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";

// Polyfills for IE11
import "nodelist-foreach-polyfill";
import "./lib/array-from.polyfill";
// import './lib/array-find.polyfill';
import "./lib/classlist.polyfill";
import form from "./lib/form";

// import './lib/array-fill.polyfill';

import debounce from "./lib/debounce";
import ui from "./reducers/ui";
import sitemap from "./lib/sitemap";
import breakpointListener from "./lib/breakpoints";
import getScript from "./lib/get-script";
import throttle from "./lib/throttle";
import cookie from "./lib/cookie";
import cardFlip from "./lib/cardflip";
import overlay from "./lib/overlay";
import modal from "./lib/modal";
import showHoverText from "./lib/506-show-text";
import tooltip from "./lib/507-tooltip";
import navigation from "./lib/600-navigation";
import HelpDropDown from "./lib/504-help-dropdown";
import bubbles from "./lib/511-bubble-experience";
import anchors from "./lib/anchors-smooth-scroll";
import popups from "./lib/popup";
import externalLinks from "./lib/526-third-party-links";
import ready from "./lib/on-ready";

{
  const store = createStore(
    ui,
    {
      currentPage: null,
      cards: {
        status: false,
        index: 0,
      },
      emailOverlay: false,
      coins: {
        maxEnergy: 5,
      },
      overlay: false,
      modal: false,
      show: {
        selected: false,
        text: "",
        index: 0,
      },
      menu: { open: false, itemOpen: null },
      helpDropDown: {
        isOpen: false,
      },
      kaleidoscope: {
        firework: 0,
      },
      findingBalance: {
        showError: false,
      },
      thumbnailComponent: {
        showImage: "",
      },
      cycleWidget: 0,
    },
    devToolsEnhancer()
  );

  window.store = store;

  const getParameterByName = (name) => {
    const match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
  };

  const getInternetExplorerVersion = () => {
    let rv = -1;

    if (navigator.appName === "Microsoft Internet Explorer") {
      const ua = navigator.userAgent;
      const re = new RegExp("MSIE ([0-9]{1,}[.0-9]{0,})");

      if (re.exec(ua) != null) rv = parseFloat(RegExp.$1);
    } else if (navigator.appName === "Netscape") {
      const ua = navigator.userAgent;
      const re = new RegExp("Trident/.*rv:([0-9]{1,}[.0-9]{0,})");

      if (re.exec(ua) != null) rv = parseFloat(RegExp.$1);
    }

    return rv;
  };

  //	-----------------	//
  //	| On DOM Loaded |	//
  //	-----------------	//
  ready(() => {
    let map = new sitemap();

    let bp = new breakpointListener();

    if (document.querySelector(".main-navigation")) {
      let menu = new navigation();
    }

    //	-------------------------- //
    //	| Help Drop Down Init    | //
    //	-------------------------- //
    HelpDropDown();

    // add IE class if needed
    const ieVersion = getInternetExplorerVersion();
    if (ieVersion !== -1) document.body.classList.add(`ie-${ieVersion}`);

    //	-------------------------- //
    //	| Overlay Initialization | //
    //	-------------------------- //
    overlay();

    //	--------------------- //
    //	| Video Modal | //
    //	--------------------- //

    modal(".video-grid__list-item, .video__list-item", {
      modalActiveClassname: "js-video-modal--active", // active class applied to body
      modalTemplate: "video-overlay", // hbs template to use as overlay instead of default
      modalCloseSelector: ".video-overlay__close",
      onOpen: function () {
        bc(document.getElementById("player"));
        videojs("player").ready(function () {
          this.play();
        });
      },
      onClickBind: (event) => {
        return {
          videoId: event.currentTarget.getAttribute("data-videoID"),
        };
      },
    });

    const page_contains_a_video = document.querySelector(".video-grid, .video");

    // get external js for video
    if (page_contains_a_video) {
      getScript(
        "https://players.brightcove.net/1905768940001/BkjVe1UXg_default/index.min.js",
        () => {
          setTimeout(function () {
            if (
              document.getElementsByClassName("vjs-errors")[0] &&
              document.getElementsByClassName("ie-10")[0]
            ) {
              document
                .getElementsByClassName("vjs-errors")[0]
                .classList.remove("vjs-errors");
            }
          }, 600);

          const vjsErrorElement = document.querySelector("vjs-errors");

          if (vjsErrorElement && document.getElementsByClassName("ie-10")[0]) {
            vjsErrorElement.classList.remove("vjs-errors");
          }
        }
      );
    }

    //	--------------------- //
    //	| Hover Text        | //
    //	--------------------- //
    //Instantiate Hover text is true
    if (document.querySelector(".show-text__container")) {
      showHoverText();
    }

    //Instantiate Tippy when a tippy element is detected
    if (document.querySelector(".tippy")) {
      tooltip();
    }

    //Instantiate smooth scrolling anchors => .anchor to #id
    anchors();
  });

  //	--------------------------	//
  //	| On DOM & Assets Loaded |	//
  //	--------------------------	//
  window.addEventListener("load", () => {
    // Instantiate component 511/514 => '/manage-stress', '/be-kind-to-yourself'
    bubbles();
    // Instantiate popup
    popups();
    // instantiate third party link popups
    externalLinks();
  });

  //	-----------------	//
  //	|   Surveys    |  //
  //	-----------------	//
  document.addEventListener("DOMContentLoaded", () => {
    let formClass = form();

    var getQueryString = function (field, url) {
      var href = url ? url : window.location.href;
      var reg = new RegExp("[?&]" + field + "=([^&#]*)", "i");
      var string = reg.exec(href);
      return string ? string[1] : null;
    };

    document.getElementById("prospect").value = getQueryString("prospect_id");
    document.getElementById("cc").value = getQueryString("cc");
    document.getElementById("csid").value = getQueryString("csid");
    document.getElementById("cglid").value = getQueryString("cglid");
    document.getElementById("utmSource").value = getQueryString("utm_source");
    document.getElementById("utmMedium").value = getQueryString("utm_medium");
    document.getElementById("utmTerm").value = getQueryString("utm_term");
    document.getElementById("utmContent").value = getQueryString("utm_content");
    document.getElementById("utmCampaign").value =
      getQueryString("utm_campaign");

    // add IE class if needed
    const ieVersion = getInternetExplorerVersion();
    if (ieVersion !== -1) document.body.classList.add(`ie-${ieVersion}`);

    let hiddenSelector = document.getElementsByClassName("range-selector");

    for (let i = 0; i < hiddenSelector.length; i++) {
      //number of inputs before hide them no survey 2
      if (i >= 42) {
        hiddenSelector[i].classList.add("hidden-selector");
      }
    }

    try {
      document
        .getElementById("next-button")
        .addEventListener("click", function (e) {
          let gotHidden = document.querySelectorAll(".body-copy");
          gotHidden[0].classList.add("hidden");
          gotHidden[1].innerHTML =
            "Please answer all the questions in this section.";
          e.preventDefault();
          if (formClass.validate(hiddenSelector) == true) {
            document.getElementById("question-set-1").classList.add("hidden");
            document
              .getElementById("question-set-2")
              .classList.remove("hidden");
            window.scrollTo({ top: 0, behavior: "smooth" });
            for (let i = 0; i < hiddenSelector.length; i++) {
              //number of inputs before hide them on survey 2
              if (i >= 42) {
                hiddenSelector[i].classList.remove("hidden-selector");
              } else {
                hiddenSelector[i].classList.add("hidden-selector");
              }
            }
          }
        });
    } catch (err) {}
  });

  //	-----------------	//
  //	|   Flip Cards   |  //
  //	-----------------	//
  cardFlip();
}
